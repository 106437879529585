import React, { useEffect, useState } from "react";
import { signOut, onAuthStateChanged } from "firebase/auth"
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { uid } from "uid";
import { set, ref, onValue, remove, update } from "firebase/database";

export default function HomePage() {
    const navigate = useNavigate();
    const [todo, addTodo] = useState("");
    const [todos, readTodo] = useState([]);
    const [email, setEmail] = useState("");
    const [edit, setEdit] = useState(false);
    const [tempUidd, setTempUidd] = useState("");

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                onValue(ref(db, `/${auth.currentUser.uid}`), snapshot => {
                    readTodo([]);
                    const data = snapshot.val();
                    setEmail(user.email);
                    if (data !== null) {
                        Object.values(data).map(todo => {
                            readTodo((oldArray) => [...oldArray, todo]);
                        })
                    }
                })
            } else if (!user) {
                navigate("/");
            }
        })
    }, [])

    const handleSignOut = () => {
        signOut(auth).then(() => {
            navigate("/")
        }).catch((err) => {
            alert(err.message);
        });
    };




    //add
    const writeTo = () => {
        const uidd = uid();
        set(ref(db, `/${auth.currentUser.uid}/${uidd}`), {
            todo: todo,
            uidd: uidd,
        })
        addTodo("");
    };
    //update
    const handleEdit = (todo) => {
        setEdit(true);
        addTodo(todo.todo);
        setTempUidd(todo.uidd);
    }
    const handleEditConfirm = () => {
        update(ref(db, `/${auth.currentUser.uid}/${tempUidd}`), {
            todo: todo,
            tempUidd: tempUidd
        });
        setEdit(false);
        addTodo("");
    }
    //delete
    const handleDelete = (uid) => {
        remove(ref(db, `/${auth.currentUser.uid}/${uid}`));
    }

    return (
        <div className=" flex justify-evenly flex-col items-center">
            <div className="flex flex-row justify-between items-center w-[100%]">
                <h1>{email}</h1>
                <h1 className="text-yellow-400 text-5xl mb-8">Todo list</h1>
                {email == "pavlak123789@seznam.cz" ? (<h1>Ahoj lásko</h1>) : (<h1>Ahoj</h1>)}
                <button onClick={handleSignOut}>sign out</button>
            </div>

            <input className=" border-green-500 border-2 w-[40%]" type="text" placeholder="Todo add..." value={todo} onChange={(e) => addTodo(e.target.value)} />

            {edit ? (<div><button onClick={handleEditConfirm}>Confirm</button></div>) : (<div><button onClick={writeTo}>add</button></div>)}



            <div className=" border-4 border-purple-600 p-2 flex justify-center items-center flex-col">
                {todos.map((todo) => (
                    <div className=" flex justify-between m-1">
                        <h1 className=" bg-slate-400">{todo.todo}</h1>
                        <div className=" ml-6">
                            <button className=" mr-3" onClick={() => handleEdit(todo)}>Edit</button>
                            <button onClick={() => handleDelete(todo.uidd)} > Remove</button>
                        </div>
                    </div>

                ))}
            </div>


        </div >
    )
}