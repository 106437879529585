// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCNZLiRRElKbi-mQyArtt0m21AimtAL0sA",
    authDomain: "react-app-2e141.firebaseapp.com",
    databaseURL: "https://react-app-2e141-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "react-app-2e141",
    storageBucket: "react-app-2e141.appspot.com",
    messagingSenderId: "172236517439",
    appId: "1:172236517439:web:580dacb587d91593db088a",
    measurementId: "G-36D3KCS7T2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app);
export const auth = getAuth();