import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, onAuthStateChanged, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

export default function Welcome() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [isRegistering, setIsRegistering] = useState(false);
    const [registerInfo, confirmRegisterInfo] = useState({
        password: "",
        confirmPassword: ""
    });

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                navigate("/todo");
            }
        })
    }, [])

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSignIn = () => {
        signInWithEmailAndPassword(auth, email, password).then(() => { navigate("/todo") }).catch((err) => alert(err.message));
    }

    const handleRegister = () => {
        if (registerInfo.password !== registerInfo.confirmPassword) {
            alert("Please check or confirm your password")
            return
        }
        createUserWithEmailAndPassword(auth, email, registerInfo.password).then(() => { navigate("/todo") }).catch((err) => alert(err.message));
    };

    return (
        <div className=" flex justify-evenly items-center flex-col m-auto h-[50vh]">
            {isRegistering ? (
                <>
                    <h1 className=" text-5xl">Register</h1>

                    <div className="flex justify-evenly items-center flex-col">
                        <input className="border-2 border-rose-500" type="email" placeholder="E-mail" onChange={handleEmailChange} value={email} />
                        <input className="border-2 border-rose-500" type="text" placeholder="Password" onChange={(e) => confirmRegisterInfo({ ...registerInfo, password: e.target.value })} value={registerInfo.password} />
                        <input className="border-2 border-rose-500" type="text" placeholder="Confirm password" onChange={(e) => confirmRegisterInfo({ ...registerInfo, confirmPassword: e.target.value })} value={registerInfo.confirmPassword} />
                        <div className=" mt-8 flex justify-center items-center flex-col ">
                            <button className="m-1 p-1 text-lg border-2 border-red-500 rounded-[25px]" onClick={handleRegister}>Sign in</button>
                            <button className="m-1 p-1 text-lg" onClick={() => setIsRegistering(false)}>Login</button>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <h1>Login</h1>

                    <div className="flex justify-evenly items-center flex-col">
                        <input className="border-2 border-rose-500" type="email" onChange={handleEmailChange} value={email} />
                        <input className="border-2 border-rose-500" type="password" onChange={handlePasswordChange} value={password} />
                        <button onClick={handleSignIn}>Sign in</button>
                        <button onClick={() => setIsRegistering(true)}>Create an account</button>
                    </div>
                </>
            )}
        </div>
    )
}