import './App.css';
import Welcome from "./components/Welcome";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import HomePage from './components/HomePage';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Welcome />} />
          <Route path='/todo' element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
